import React from "react";
import styled, { css }  from "styled-components";
import { ContentWrapper, Background as SectionBackground } from "../components";
import ReactMarkdownWithHtml from "react-markdown/with-html";

const SectionContentWrapper = styled(props => <ContentWrapper {...props} />)`
  margin: 0 !important;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionIcon = styled.img`
  max-width: 65px;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 768px) {
    margin: 10px 25px;
  }
`;

const SectionContent = styled.div`
  padding: 0 25px;
  color: inherit;
  width: 100%;
  font-size: 10pt;

  ${props =>
    props.backdrop
      ? css`
          background-color: rgb(89,89,89, 0.8);
          padding-top: 20px;
          * {
            color: white !important;
          }
        `
      : ''};
`;

const SectionHeader = styled.h2`
  color: inherit;
  margin: 0;
  font-size: 14pt;
  font-weight: 500;
  padding-bottom: 10px;
`;

const SectionSubheading = styled.p`
  font-size: 10pt;
  white-space: pre-wrap;
  color: inherit;
`;

const Section = ({ image, icon, heading, content, children }) => {
  return (<SectionBackground image={image}>
    <SectionContentWrapper>
      {icon ? <SectionIcon src={icon} /> : <></>}
      <SectionContent backdrop={!!image}>
        <SectionHeader>{heading}</SectionHeader>
        <SectionSubheading>
          <ReactMarkdownWithHtml children={content} allowDangerousHtml />
        </SectionSubheading>
        {children}
      </SectionContent>
    </SectionContentWrapper>
  </SectionBackground>
  );
}

export default Section;
