import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import {grey} from '../utils/colors'

import { ContentWrapper } from "./atoms.js";

const Footer = styled.footer`
  max-width: 100vw;
  padding: 10px;
  margin: 0;
  background-color: ${grey};
  color: white;
`;

const Wrapper = styled(props => <ContentWrapper {...props} />)`
  flex-direction: row;
  flex-wrap: wrap;
  & * {
    font-size: 8.5pt;
    color: white;
  }
`;

const MiniHeader = styled.strong`
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  color: white;
`;

const Left = styled.div`
  line-height: 20px;
  padding-right: 10px;
`;

const Right = styled.div`
  flex: 3;
  text-align: right;
`;

const FooterLinks = styled.ul`
  list-style: none;
  margin: 38px 0 10px 0;
  padding: 0;
  text-align: right;
`;

const FooterLink = styled.li`
  padding: 0 8px;
  border-right: solid 1px white;
  display: inline-block;
  &:last-child {
    border: none;
  }
  & a {
    text-decoration: none;
    &:hover {
      font-weight: 500;
    }
  }
  @media (max-width: 400px) {
    border: none;
    font-size: 0.9em;
    text-align: right;
    padding: 10px 10px;
    display: block;
  }
`;

const Copyright = styled.div`
  border-top: solid 1px rgba(255, 255, 255, 0.5);
  width: 100%;
  padding: 10px 0;
  display: block;
`;

const FooterComponent = () => {
  const data = useStaticQuery(graphql`
    {
      footer: footerJson {
        address {
          name
          address1
          address2
          city
          postcode
        }
        linkedIn
        notice
        copyright
        links {
          text
          slug
          prefix
        }
      }
      logo: file(name: { eq: "linkedin-logo" }) {
        publicURL
      }
    }
  `);
  const { address, notice, copyright, links, linkedIn } = data.footer;
  return (
    <Footer>
      <Wrapper>
        <Left>
          <MiniHeader>Contact Us</MiniHeader>
          {address.name}
          <br />
          {`${address.address1} ${address.address2}`}
          <br />
          {`${address.city} ${address.postcode}`}
          <br />
          <a target="_blank" rel="noopener noreferrer" href={linkedIn}>
            <img className="mb-3"
              alt="LinkedIn Logo" src={data.logo.publicURL} />
          </a>
        </Left>
        <Right>
          <p>{notice}</p>
          <br />
          <FooterLinks>
            {links.map((link, index) => (
              <FooterLink key={index}>
                <Link to={`${link.prefix}/${link.slug}`}>{link.text}</Link>
              </FooterLink>
            ))}
          </FooterLinks>
        </Right>
      </Wrapper>
      <Wrapper>
        <Copyright>{copyright}</Copyright>
      </Wrapper>
    </Footer>
  );
};

export default FooterComponent;
