import React from "react";
import styled, { css }  from "styled-components";
import { ContentWrapper } from "../components";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import { white, darkGrey } from "../utils";

export const Wrapper = styled.section`
  width: 100%;
  ${props =>
    props.image && props.image !== ""
      ? css`
          background-image: url(${props => props.image});
        `
      : css`
          background-image: none;
          background-color: ${white};
          color: ${darkGrey};
        `};
  background-position: center;
  background-size: cover;
  backgroud-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  position: relative;
  padding: 10px;
  min-height: calc(100vh - 350px);
`;

const SectionContentWrapper = styled(props => <ContentWrapper {...props} />)`
  margin: 0 !important;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionIcon = styled.img`
  max-width: 65px;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 768px) {
    margin: 10px 25px;
  }
`;

const SectionContent = styled.div`
  color: inherit;
  width: 100%;
  font-size: 10pt;
  display: flex;

  ${props =>
    props.backdrop
      ? css`
          background-color: rgb(89,89,89, 0.8);
          * {
            color: white !important;
          }
        `
      : ''};
`;

const SectionHeader = styled.h2`
  color: inherit;
  margin: 0;
  font-size: 14pt;
  font-weight: 500;
  padding-bottom: 10px;
`;

const SectionSubheading = styled.p`
  font-size: 10pt;
  white-space: pre-wrap;
  color: inherit;
`;

const FundSection = ({ icon, heading, content, children }) => {
  return (<Wrapper>
    <SectionContentWrapper>
      {icon ? <SectionIcon src={icon} /> : <></>}
      <SectionContent>
        <SectionHeader>{heading}</SectionHeader>
        <SectionSubheading>
          <ReactMarkdownWithHtml children={content} allowDangerousHtml />
        </SectionSubheading>
        {children}
      </SectionContent>
    </SectionContentWrapper>
  </Wrapper>
  );
}

export default FundSection;
