import React from "react";
import { createGlobalStyle } from "styled-components";
import { Navbar, Footer } from "../components";
import { red } from "../utils";
import { Helmet } from "react-helmet";
import SiteDisclaimer from "../components/SiteDisclaimer";
import {CookiesProvider} from 'react-cookie';


const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  color: black;
  font-family: 'roboto', sans-serif;
  font-size: 10pt;
}
h1,h2,h3,h4,h5 {
  color: black;
  letter-spacing: initial;
}
html {
  width: 100%;
  }
  body {
    width: 100%;
    margin: 0;
  }
  a {
    color: ${red};
  }
`;

export default ({ children, title }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{title ? title + ' » Stratton' : 'Stratton'}</title>
      </Helmet>
      <GlobalStyle />
      <Navbar />
      {children}

      <CookiesProvider>
        <SiteDisclaimer />
      </CookiesProvider>
    </React.Fragment>
  )
};
