import styled, { css } from "styled-components";

import { paleGrey, white, darkGrey, maxWidth } from "../utils";

export const Background = styled.section`
  width: 100%;
  ${props =>
    props.image && props.image !== ""
      ? css`
          background-image: url(${props => props.image});
        `
      : css`
          background-image: none;
          background-color: ${white};
          color: ${darkGrey};
        `};
  background-position: center;
  background-size: cover;
  backgroud-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  padding: 20px 30px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const PageWrapper = styled.div`
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 350px);
`;

export const ContentWrapper = styled.div`
  max-width: ${maxWidth};
  margin: 0 auto;
  display: flex;
  color: inherit;
  width: 100%;
`;

export const Header = styled.h1`
  color: ${props => (props.color ? props.color : "inherit")};
  background: ${props => (props.image)};
  font-weight: normal;
  font-size: 20pt;
  margin-top: 110px;
  font-weight: normal;
  background-color: rgb(89,89,89, 0.75);
  width: calc(100% + 60px);
  margin-left: -60px;
  margin-right: -60px;
  padding: 5px 60px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;
