import styled from "styled-components";
import { grey, paleGrey, veryLightGrey } from '../utils/colors';

export const FlipCard = styled.div`
  background-color: transparent;
  width: ${props => props.width || '250px'};
  height: ${props => props.height || '250px'};
  min-height: ${props => props.height || '250px'};
  margin: 10px;
`;

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
  background-image: url(${props => props.image || ''});
  background-size: auto 100%;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  > div:nth-child(2) {
    > div {
      transition: all 0.5s;
    }
  }

  :hover{
    background-color: transparent;
    box-shadow: 5px 10px 8px ${paleGrey};
    > div:first-child {
      flex-grow: 0;
      background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
    }
    > div:nth-child(2) {
      > div {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    > div:last-child {
      opacity: 0.8;
      max-height: 100%;
      flex-basis: 20px;
    }
  }
`;

const FlipCardDefault = `
  color: ${props => props.secondary ? grey : veryLightGrey};
  background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
  transition: all 0.5s;
  max-height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const FlipCardPadder = styled.div`
  flex-grow: 1;
  transition: all 0.5s;
  background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
`;

export const FlipCardFront = styled.div`
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
`;


export const FlipCardBack = styled.div`
  ${FlipCardDefault}
  max-height: 0;
  transform-origin: bottom;
  background-color: ${props => props.secondary ? veryLightGrey : 'transparent'};
  overflow-y: auto;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) );
  
  -ms-overflow-style: none;
  scrollbar-width: none; 
  &::-webkit-scrollbar {
    display: none;
  }
`;