export const blue = "#124b6d";
export const white = "#ffffff";
export const black = "#000000";
export const paleBlue = "#d8e2e8";
export const paleGrey = "rgb(242, 242, 242)";
export const grey = "rgb(89, 89, 89)";
export const darkGrey = "#484848";
export const veryDarkBlue = "#0b2240";
export const veryLightBlue = "#edf1f4";
export const veryLightGrey = paleGrey;
export const red = "#dd052d";

// Chart Colors
export const chartNavyBlue = "#0c2340";
export const chartLightBlue = "#0095c8";
export const chartIceBlue = paleGrey;
export const chartGrey = "#c4bcb7";
export const chartPink = "#ffafff";
export const chartFuschia = "#ac4fc6";
export const chartPurple = "#7030a0";
export const chartYellow = "#ffc000";
export const chartOrange = "#ff9933";
export const chartRed = "#cc0000";
export const chartbrown = "#800000";

//Chart label
export const chartLabelBlack = "#000000";
export const chartLabelAlmostBlack = "#262626";
export const chartLabelDarkGrey = "#404040";
export const chartLabelGrey = "#595959";
export const chartLabelLightGrey = "#7F7F7F";
export const chartLabelAsh = "#A6A6A6";

export const axisColour = chartLabelBlack;

export const GetLabelledChartColors = () => {
  return [
    chartLabelBlack,
    chartLabelAlmostBlack,
    chartLabelDarkGrey,
    chartLabelGrey,
    chartLabelLightGrey,
    chartLabelAsh
  ]
}

export const GetChartColours = (numDataPoints) => {
  return [
    red,
    '#FDBF2D',
    '#1EB1ED',
    '#AC4FC6',
    '#7F7F7F',
    red,
    '#FDBF2D',
    '#1EB1ED',
    '#AC4FC6',
    '#7F7F7F'
  ];
}