import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import { black, white } from "../utils";

const breakpoint = "944px";
const navbarHeight = "110px";

const Wrapper = styled.nav`
  max-height: ${navbarHeight};
  height: ${navbarHeight};
  color: ${white};
  background: none;
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  transition: background-color 0.5s ease-out;
  background-color: transparent;
  background-repeat: repeat-x;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

  &.shrink {
    background-color: white;
  }

  @media (max-width: ${breakpoint}) {
    justify-content: flex-start;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 20px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 2;
  border-right: 1px solid ${white};
  @media (max-width: ${breakpoint}) {
    position: absolute;
    top: ${navbarHeight};
    display: none;
    width: 100vw;
    z-index: 3;
    background-color: ${white};
  }
`;

const ListItem = styled.li`
  border: none;
  width: auto;
  border-left: 1px solid ${white};
  padding-top: 4px;
  padding-bottom: 4px;
`;

const LinkStyles = `
  display: block;
  font-size: 11pt;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &:hover {
    background-color: ${white};
    opacity: 0.8;
    color: ${black};
  }
  @media (max-width: ${breakpoint}) {
    padding: 25px 0;
  }
  &:hover {
    text-decoration: none;
  }
`;

const StyledLink = styled(props => <Link {...props} />)`
  ${LinkStyles}
  padding: 15px 10px;
  background-color: rgb(89,89,89, 0.75);
  color: white;
`;

const LogoContainer = styled.div`
  margin: 0 30px;
  flex: 1;
  height: 100%;
  display: flex;
`;

const Logo = styled(props => <Link {...props} />)` 
  height: 100%;
  background-image: url(${props => props.image});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left;
  flex: 1;
  max-width: 400px;

  @media (max-width: ${breakpoint}) {
    max-width: 285px;
  }
`;

const MenuButtonCover = styled.img`
  display: none;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 25px;
  z-index: 3;
  pointer-events: none;
  margin-top: 35px;

  @media (max-width: ${breakpoint}) {
    display: block;
  }
`;

const MenuButton = styled.input`
  display: none;
  opacity: 0;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 25px;
  z-index: 2;
  margin: 0;
  cursor: pointer;
  margin-top: 35px;

  @media (max-width: ${breakpoint}) {
    display: block;
  }
  &:checked ~ ${List} {
    display: block;
    margin: 0;
    border: none;
    li {
      border: none;
      a {
        text-align: left;
        padding: 10px;
      }
    }
  }
  &:checked ~ ${MenuButtonCover} {
    outline: solid 2px ${white};
  }
`;

const NavItemDropdown = styled.li`
  display: inline-block;
  height: 100%;
  cursor: pointer;
  border-left: 1px solid ${white};
  padding-top: 4px;
  padding-bottom: 4px;

  &:hover div {
    display: block;
  }

  @media (max-width: ${breakpoint}) {
    width: 100%;
  }
`;

const NavItemDropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;§
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
`;

const NavItemParent = styled.a`
  ${LinkStyles}
  padding: 15px 10px;
  color: white;
  background-color: rgb(89,89,89, 0.75);
  > span {
    display: none;
  }
`;

const NavItemChild = styled(Link)`
  ${LinkStyles}
  font-size: 0.8rem;
`;

const ChildLinks = (link) => (
  <>
    <NavItemParent href="#">{link.text}</NavItemParent>
    <NavItemDropdownContent>
      {link.children.map((childLink, index) => (
        <NavItemChild key={index} to={childLink.slug} >
          {childLink.text}
        </NavItemChild>
      ))}
    </NavItemDropdownContent>
  </>
)

export default () => {
  const data = useStaticQuery(graphql`
    {
      navbar: navbarJson {
        image
        links {
          text
          slug
          children {
            text
            slug
          }
        }
      }
      logo: file(name: { eq: "menu" }) {
        publicURL
      }
      headerLogo: file(name: { eq: "logo" }) {
        publicURL
      }
    }
  `);

  if (typeof window !== 'undefined') {
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;
      const navbarElement = document.getElementById("navbar");
      if (navbarElement) {
        if (currentScrollPos <= 30) {
          navbarElement.classList.remove('shrink');
        } else {
          navbarElement.classList.add('shrink');
        }
      }
    }
  }

  return (
    <Wrapper id="navbar">
      <LogoContainer>
        <Logo image={data.headerLogo.publicURL} to="/"></Logo>
      </LogoContainer>
      <List>
      </List>
    </Wrapper>
  );
}
