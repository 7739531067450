import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default ({ pageContext, relativePath }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? `/${relativePath}` : `/${relativePath}/${(currentPage - 1).toString()}`;
  const nextPage = `/${relativePath}/${(currentPage + 1).toString()}`;
  return (
    <Wrapper>
      {!isLast && (
        <Link to={nextPage} rel="next">
          ← Older Articles
        </Link>
      )}
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          Newer Articles →
        </Link>
      )}
    </Wrapper>
  );
};
